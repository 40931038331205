// Generated by @hypernym/nuxt-gsap
import { defineNuxtPlugin } from '#app';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { CustomEase } from 'gsap/CustomEase';
const plugin = defineNuxtPlugin(() => {
  if(process.client) {
  gsap.registerPlugin(ScrollTrigger,MotionPathPlugin,CustomEase);
  
  
}
  return {
    provide: {
      gsap,
      ScrollTrigger,
MotionPathPlugin,
CustomEase
    }
  }
})
export default plugin;