<template>
  <NuxtLayout>
    <DsPageContent>
      <div class="ds-container">
        <div class="ds-grid ds-justify-content-center">
          <div
            class="ds-grid__col--12 ds-md-grid__col--8 ds-padding-y-48 ds-md-padding-y-96 ds-padding-x-24 ds-text-align-center"
          >
            <svg
              class="image"
              viewBox="0 0 526 300"
            >
              <path
                class="st0"
                d="M373.6,299.4l-52.3-52.3c-0.8-0.8-0.8-2,0-2.8l11.3-11.3c0.8-0.8,2-0.8,2.8,0l52.3,52.3c0.8,0.8,0.8,2,0,2.8
                l-11.3,11.3C375.6,300.2,374.4,300.2,373.6,299.4z"
              />
              <circle
                class="st1"
                cx="263"
                cy="174.7"
                r="100"
              />
              <g class="st2">
                <polyline points="133,224.7 33,224.7 133,74.7 133,274.7" />
                <polyline points="493,224.7 393,224.7 493,74.7 493,274.7" />
              </g>
              <defs>
                <circle
                  id="SVGID_1_"
                  cx="394.1"
                  cy="29.9"
                  r="28.9"
                />
              </defs>
              <use
                xlink:href="#SVGID_1_"
                class="st10"
              />
              <clipPath id="SVGID_2_">
                <use
                  xlink:href="#SVGID_1_"
                  style="overflow: visible;"
                />
              </clipPath>
              <path
                class="st3"
                d="M363,22h10c2.2,0,4,1.8,4,4v10c0,2.2,1.8,4,4,4h10c2.2,0,4,1.8,4,4v20l-29-5.2L363,22z"
              />
              <path
                class="st3"
                d="M394.1,1v11c0,2.2,1.8,4,4,4h4.9c2.2,0,4,1.8,4,4v13c0,2.2,1.8,4,4,4l0,0c2.2,0,4-1.8,4-4v-2c0-2.2,1.8-4,4-4
              h3.8h5.2V-9h-35L394.1,1z"
              />
              <use
                xlink:href="#SVGID_1_"
                class="st11"
              />
              <line
                class="st4"
                x1="203.9"
                y1="200.6"
                x2="324.1"
                y2="150.8"
              />
              <defs>
                <circle
                  id="SVGID_3_"
                  cx="264"
                  cy="175.7"
                  r="44.8"
                />
              </defs>
              <use
                xlink:href="#SVGID_3_"
                class="st12"
              />
              <clipPath id="SVGID_4_">
                <use
                  xlink:href="#SVGID_3_"
                  style="overflow: visible;"
                />
              </clipPath>
              <g class="st5">
                <circle
                  cx="246.8"
                  cy="134.1"
                  r="10"
                />
                <ellipse
                  cx="302.3"
                  cy="197.7"
                  rx="15"
                  ry="15"
                />
                <line
                  x1="203.9"
                  y1="200.6"
                  x2="324.1"
                  y2="150.8"
                />
              </g>
              <g class="st7">
                <circle
                  cx="252.4"
                  cy="200"
                  r="5"
                />
                <circle
                  cx="273"
                  cy="150.3"
                  r="5"
                />
              </g>
              <g class="st8">
                <circle
                  cx="187"
                  cy="35.7"
                  r="10"
                />
                <circle
                  cx="194"
                  cy="42.7"
                  r="5"
                />
                <circle
                  cx="520"
                  cy="269.7"
                  r="5"
                />
                <circle
                  cx="38"
                  cy="126"
                  r="5"
                />
              </g>
            </svg>
            <h1 class="ds-heading-large ds-text-align-center ds-padding-top-24">
              404, page not found
            </h1>
            <p class="ds-text-medium ds-text-align-center ds-padding-y-24">
              The page you were looking for could not be found. We may have deleted or moved this page. Or the link
              never existed.
            </p>
            <DsLink
              :open-in-new-window="false"
              content="Go back to the homepage"
              :url="localeHomepageUrl"
              type="button"
            />
          </div>
        </div>
      </div>
    </DsPageContent>
  </NuxtLayout>
</template>

<script setup>
import { DsPageContent, DsLink } from 'awds'

const { locale: localeRef } = useI18n()

const localeHomepageUrl = computed(() => {
  if (localeRef.value === 'en') {
    return '/'
  }
  return '/' + localeRef.value
})

defineProps({
  error: {
    type: Object,
    default: null
  }
})
</script>

<style lang="scss" scoped>
@import 'awds/foundation-vars.scss';

.image {
  max-width: 526px;
  width: 100%;
}

.st0 {
  fill: $color-black;
}

.st1 {
  fill: $color-white;
  stroke: $color-black;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.st2 {
  fill: none;
  stroke: $color-black;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.st3 {
  clip-path: url('#SVGID_2_');
  fill: $color-white;
  stroke: $color-black;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.st4 {
  fill: none;
  stroke: $color-green;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.st5 {
  clip-path: url('#SVGID_4_');
  fill: none;
  stroke: $color-white;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.st7 {
  clip-path: url('#SVGID_4_');
  fill: none;
  stroke: $color-white;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.st8 {
  fill: $color-white;
  stroke: $color-black;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.st10 {
  fill: $color-white;
  overflow: visible;
}

.st11 {
  fill: none;
  overflow: visible;
  stroke: $color-black;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.st12 {
  fill: $color-green;
  overflow: visible;
}
</style>
