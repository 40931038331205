import { defineStore } from 'pinia'
import type { AdyenRoute } from '~/types'

export const useRoutesStore = defineStore('routes-adyen', () => {
  const routes: Ref<AdyenRoute[]> = ref([])
  const routesMap: Ref<{ [key: string]: AdyenRoute[] }> = ref({})
  const allRoutes: Ref<{ [key: string]: AdyenRoute[] }> = ref({})

  const { fetchRoutes } = useRoutesUtils()
  const { locale } = useI18n()
  const targetSite = useRuntimeConfig().public.targetSite

  const retrieveRoutes = async () => {
    // TODO: On language switch, clear
    if (routesMap.value[locale.value] || routesMap.value[locale.value]?.length === 0) {
      return
    }

    const _routes = await fetchRoutes(targetSite, locale.value)
    routesMap.value[locale.value] = _routes
    routes.value = _routes
    return _routes
  }

  const resetRoutes = () => {
    routesMap.value[locale.value] = []
  }

  const retrieveAllRoutes = async () => {
    // TODO: On language switch, clear
    if (allRoutes.value[locale.value] || allRoutes.value[locale.value]?.length === 0) {
      return
    }


    const _routes = await fetchRoutes('all', locale.value)
    allRoutes.value[locale.value] = _routes
    return _routes
  }

  return { retrieveAllRoutes, retrieveRoutes, resetRoutes, routes, allRoutes }
})
